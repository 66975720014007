body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input-primary {
    @apply w-full sm:max-w-[440px] h-[56px] px-4 rounded-xl border border-[#EEEEEE] bg-white;
    @apply placeholder:text-gray-800 text-base;
    @apply focus:outline-none focus:border-emerald-500 focus:ring-0;
  }

  .btn-primary {
    @apply w-full sm:max-w-[440px] h-[56px] rounded-xl bg-emerald-500 text-white font-medium;
    @apply hover:bg-emerald-600 transition-colors;
    @apply disabled:opacity-75 disabled:cursor-not-allowed;
  }
}
